<template>
<section class="bgf5">
  <header class="defi-header text-center">
    <img src="@/assets/img/logo/binance.png" width="190">
    <h1>Start your DeFi Journey on BSC</h1>
  </header>
  <article class="container">
    <main class="box-shadow-card" v-if="populars&&populars.length">
      <h3 class="card-title">{{$t('Popular')}}</h3>
      <ul class="flex-wrap-list">
        <li class="col-3" v-for="(item,i) in populars" :key="i">
          <a :href="item.url?item.url:'javascript:;'" :target="item.url?'_blank':''" rel="noopener noreferrer">
            <img v-lazy="event_oss_link+'defi/'+item.image" width="100%">
            <p class="mt-16">{{item.title}}</p>
          </a>
        </li>
      </ul>
    </main>
    <main class="box-shadow-card text-center mvb" v-if="mvbList&&mvbList.length">
      <p class="card-title">{{$t('AsWeekData').replace('d%','2021.06.25')}}</p>
      <ul class="flex-wrap-list">
        <li class="col-4" v-for="(item,i) in mvbList" :key="i">
          <p class="text-sm color-grey">{{ $t(item.title) }}</p>
          <h3 class="mt-4">{{item.num}}</h3>
        </li>
      </ul>
      <a class="event-btn" href="https://www.binance.org/en/blog/the-most-valuable-builder-mvb-ii-the-big-bang-of-nfts/" target="_blank" rel="noopener noreferrer">{{$t('ApplyAccelerate')}}</a>
    </main>
    <main class="box-shadow-card" v-if="recommends&&recommends.length">
      <h3 class="card-title">{{$t('Recommended')}}</h3>
      <div class="swiper-box">
        <swiper class="recom swiper" :options="swiperOptions">
          <swiper-slide v-for="(item,i) in recommends" :key="i">
            <a :href="item.url?item.url:'javascript:;'" :target="item.url?'_blank':''" rel="noopener noreferrer">
              <img :src="item.img" width="100%">
              <p class="mt-16 text-center text-ellipsis2">{{item.title}}</p>
            </a>
          </swiper-slide>
        </swiper>
        <!-- Add Arrows -->
        <img src="@/assets/img/event/icon/left.png" width="30" slot="button-prev" class="swiper-prev">
        <img src="@/assets/img/event/icon/right.png" width="30" slot="button-next" class="swiper-next">
      </div>
    </main>
    <main class="box-shadow-card">
      <h3 class="card-title">{{$t('Events')}}</h3>
      <p class="text-sm mt-8" v-html="$t('submitEventEntry').replace('eventsubmit','defi/eventsubmit')"></p>
      <ul class="tabs" v-if="tags&&tags.length">
        <li v-for="item in tags" :key="item.ID" :class="{active:sID==item.ID}" @click="getEventsByTag(item.ID)">{{item.title}}</li>
      </ul>
      <EventList v-if="events && events.length" :list="events" from="defi/"/>
      <p class="null">{{$t('NoInformation')}}</p>
      <p class="view-box" v-if="events&&events.length">
        <router-link class="view" to="/defi/events">{{$t('ViewMore')}}</router-link>
      </p>
    </main>
    <main class="box-shadow-card">
      <h3>{{$t('AllCategories')}}</h3>
      <p class="text-sm mt-8" v-html="$t('submitProjectEntry').replace('projectsubmit','defi/projectsubmit')"></p>
      <ul class="tabs" v-if="projectTags&&projectTags.length">
        <li v-for="item in projectTags" :key="item.ID" :class="{active:spID==item.ID}" @click="getProjectsByTag(item.ID)">{{item.title}}</li>
      </ul>
      <CateList :list="projectList" from="defi/"/>
      <p class="view-box" v-if="projectList&&projectList.length">
        <router-link class="view" to="/defi/projects">{{$t('ViewMore')}}</router-link>
      </p>
    </main>
    <main class="box-shadow-card" v-if="landscapeList&&landscapeList.length">
      <h3 class="card-title">{{$t('NFTLandscape').replace('NFT','DeFi')}}</h3>
      <div class="swiper-box nft">
        <swiper class="swiper" :options="nftSwiperOption">
          <swiper-slide v-for="item in landscapeList" :key="item.ID">
            <a :href="'/#/project/' + item.ID" target="_blank" rel="noopener noreferrer">
              <img v-lazy="project_oss_link+item.img" width="100%">
              <p class="mt-8 text-sm text-ellipsis">{{item.title}}</p>
            </a>
          </swiper-slide>
        </swiper>
        <!-- Add Arrows -->
        <img src="@/assets/img/event/icon/left.png" width="30" slot="button-prev" class="swiper-prev">
        <img src="@/assets/img/event/icon/right.png" width="30" slot="button-next" class="swiper-next">
      </div>
    </main>
  </article>
</section>
</template>
<script>
import EventList from '@/components/EventList.vue'
import CateList from '@/components/CateList.vue'
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import eventJSON from '@/assets/js/event'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide,
    EventList,
    CateList,
  },
  data(){
    return {
      swiperOptions:eventJSON.swiperOptions,
      nftSwiperOption:eventJSON.nftSwiperOption,
      recommends:eventJSON.defiRecommends,
      mvbList: eventJSON.defiMvbList,
      sID:0, //event selected ID
      spID:0,//project selected ID
      landscapeList:null,
      projectTags:null,
      populars:null,
      projectList:null,
      events:null,
    }
  },
  computed:{
    tags(){
      return [...[{ID:"0",title:'All'}],...this.defiEventTags]
    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){
      this.getTags();
      this.getEventsByTag();
      this.getUpcomings()
      this.getLandscapeList();
    },
    getLandscapeList(){
      this.axios.get(this.domain+'getBscList?limit=1000&tagID=1&flag=1').then(res=>{
        this.landscapeList = res?.data?.data
      })
    },
    getTags(){
      this.$loading(1);
      this.axios.get(this.domain+'getDefiProjectTags?type=1').then(res=>{
        let tags = res?.data?.data?.tags;

        if(tags&&tags.length){
          this.projectTags = [{
              ID:0,
              title:'All'
            },
            ...tags
          ]
          this.getProjectsByTag();
        }else{
          this.$loading(0);
        }
      })
    },
    getUpcomings(){
      this.axios.get(this.domain+'getPopularDefi').then(res=>{
        this.populars = res?.data?.data;
      })
    },
    getEventsByTag(ID = 0){
      this.$loading(1);
      this.axios.get(this.domain+'getDefiEventList?v=1.0&limit=6&page=0&eventType='+ID).then(res=>{
        this.events = res?.data?.data;
        this.sID = ID;
        this.$loading(0);
      }).catch(err=>{
        console.log(err);
        this.$loading(0);
      })
    },
    getProjectsByTag(ID = 0){
      this.$loading(1);
      this.axios.get(this.domain+'getDefiProjectList?v=1.0&limit=12&page=0&tagIDs='+ID).then(res=>{
        this.projectList = res?.data?.data;
        this.spID = ID;
        this.$loading(0);
      }).catch(err=>{
        console.log(err);
        this.$loading(0);
      })
    }
  }
}
</script>
<style scoped src="../../assets/css/event.css"></style>
