export default {
  touchList:[
    {
      title:"BSC Community",
      link:"https://community.binance.org/category/44/en-binance-smart-chain",
    },
    {
      title:"Binance Chain Blog",
      link:"https://www.binance.org/en/blog/",
    },
    {
      title:"Developer Community",
      link:"https://t.me/joinchat/IuVfSlYWC5seijz6a0Bjww",
    },
    {
      title:"Twitter",
      link:"https://twitter.com/binancechain",
    },
    {
      title:"Telegram Channel",
      link:"https://t.me/BinanceDEXchange",
    },
    {
      title:"Discord",
      link:"https://discord.com/invite/3PAqnny5Fu",
    },
    {
      title:"BSC Accelerator Fund",
      link:"https://binancex.dev/",
    },
    // {
    //   title:"Vietnam",
    //   link:"",
    // },
    // {
    //   title:"Spanish",
    //   link:"",
    // },
    // {
    //   title:"Russia",
    //   link:"",
    // },
  ],
  recommends:[{
    url:'https://www.binance.com/en/nft/landing-page',
    title:'Binance NFT Marketplace offers an open market for creators, crypto enthusiasts, and creative fans with the best liquidity and minimal fees.',
    img:require('@/assets/img/event/recommends/binance.jpg'),
  },{
    url:'https://kakanft.com/',
    title:'KAKA NFT WORLD is a DEFI project of the world’s top NFT vertical game value ecology',
    img:require('@/assets/img/event/recommends/kaka.jpg'),
  },{
    url:'https://xwg.games/',
    title:'X World Games, The virtual world of the new internet, trade NFT everywhere.',
    img:require('@/assets/img/event/recommends/xwg.jpg'),
  },{
    url:'https://www.binance.com/en/blog/421499824684902167/Introducing-Featured-by-Binance-A-Decentralized-NFT-Platform-From-Binance',
    title:'Featured by Binance，its vision is to provide a non-custodial, on-chain platform to facilitate the full lifecycle of NFTs for creators, brands and their fans.',
    img:require('@/assets/img/event/recommends/featured.jpg'),
  },{
    url:'https://twitter.com/bakery_swap',
    title:'BakerySwap, the 1st AMM + NFT Exchange, is the all-in-one DeFi platform on Binance Smart Chain.',
    img:require('@/assets/img/event/recommends/bakerySwap.jpg'),
  },{
    url:'https://mobox.io/#/',
    title:'Mobox: NFT Gaming platform on BSC',
    img:require('@/assets/img/event/recommends/mobox.jpg'),
  },{
    url:'https://www.airnfts.com/',
    title:'AirNFTs: NFT marketplace built on Binance Smart Chain-Buy,Collect,Sell NFTs.',
    img:require('@/assets/img/event/recommends/AirNFT.jpg'),
  }],
  tutorials:[{
    url:'',
    title:'How to Spot Scams in Decentralized Finance (DeFi)',
    img:require('@/assets/img/event/icon/default.png'),
  },{
    url:'',
    title:'How to Spot Scams in Decentralized Finance (DeFi)How to Spot Scams in Decentralized Finance (DeFi)',
    img:require('@/assets/img/event/icon/default.png'),
  },{
    url:'',
    title:'How to Spot ',
    img:require('@/assets/img/event/icon/default.png'),
  },{
    url:'',
    title:'How to Spot ',
    img:require('@/assets/img/event/icon/default.png'),
  }],
  swiperOptions: {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    centeredSlidesBounds:false,
    loop:true,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev'
    },
    breakpoints: {
      300: {  //当屏幕宽度大于等于360
        slidesPerView: '1',
      },
      540: {  //当屏幕宽度大于等于768
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: '30%',
          depth: 310,
          slideShadows: true,
        },
      },

    }
  },
  nftSwiperOption:{
    slidesPerColumn: 3,
    spaceBetween: 24,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev'
    },
    breakpoints: {
      320: {  //当屏幕宽度大于等于360
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      440: {  //当屏幕宽度大于等于600
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      540: {  //当屏幕宽度大于等于768
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      768: {  //当屏幕宽度大于等于950
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
      950: {  //当屏幕宽度大于等于950
        slidesPerView: 6,
        slidesPerGroup: 6,
      }
    }
  },
  nftMvbList: [
    {
      title: "MVBTOTALTVL",
      num:'$16,522,610,000',
    },
    {
      title: "MVBActiveAddress",
      num:'313,234',
    },
    {
      title: "TotalMVBProject",
      num:'415',
    },
    {
      title: "Newjoiningprojects",
      num:'13',
    },
  ],
  defiRecommends: null,
  defiMvbList: null,
}
